<template>
	<v-form
		ref="form"
		lazy-validation
	>
		<v-col cols="12">
			<h2 class="font-weight-300">Your Scan Documents With Company Data
			</h2>
		</v-col>

		<v-col cols="12">
			<v-alert type="info" dense outlined class="text-body-2 font-weight-500">
				Use button "ctrl", to select more files.
			</v-alert>

			<v-file-input
				v-model="files"
				counter
				label="Files"
				multiple
				placeholder="Select your files"
				prepend-icon="fas fa-copy"
				accept="image/png, image/jpeg, application/pdf"
				outlined
				ref="formFiles"
				:show-size="1000"
				:rules="[
					value => !!value.length || 'Field is required!',
					value => !value || getSize(value) || 'Files should be less than 10 MB!',
				]"
			>
				<template v-slot:selection="{ index, text }">
					<v-chip
						color="primary"
						dark
						label
						small
					>
						{{ text }}
					</v-chip>
				</template>
			</v-file-input>
		</v-col>

		<v-col cols="12">
			<h2 class="font-weight-300">Regulations</h2>
		</v-col>

		<v-col cols="12">
			<v-list dense class="pa-0">
				<v-list-item
					v-for="(item, index) in prepareApprovals"
					:key="index"
				>

					<v-list-item-action>
						<v-checkbox
							class=""
							label=""
							:value="false"
							:false-value="false"
							:true-value="true"
							v-model="checkbox[item.slug]"
							hide-details="auto"
							:rules="[v => (!!v || !item.required ) || 'Please accept the required regulations!']"
						/>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title
							class="text-body-1 font-weight-400 black--text text-wrap pb-1"
						>
							<span v-if="item.required" class="red--text text-h6">* </span>
							<span class="" v-html="item.name"/>
						</v-list-item-title>
						<small class="red--text mt-1" v-if="item.required && !checkbox[item.slug]">
							Please accept the required regulations!
						</small>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-col>

		<v-col cols="12" xs="12" class="d-flex align-center">
			<v-spacer/>
			<div class="grey--text text--darken-2 font-weight-500 mr-2">
				Step {{ activeStep }} of {{ countStep }}
			</div>
			<v-btn
				outlined
				large
				color="secondary"
				@click="back()"
				class="ma-1"
			>
				BACK
			</v-btn>
			<v-btn
				large
				color="primary"
				@click="register()"
				class="ma-1"
				:loading="loadingBtnAction"
			>
				REGISTER
			</v-btn>
		</v-col>
	</v-form>
</template>
<script>
import {validationRules} from '@/helpers/validationRules'

export default {
	name: 'BlockRegisterFilesAndTerms',
	components: {},
	computed: {
		activeStep() {
			return this.$store.getters['REGISTER_STORE/STEP'];
		},
		countStep() {
			return this.$store.getters['REGISTER_STORE/STEP_COUNT'];
		},
		email() {
			return this.$store.getters['REGISTER_STORE/EMAIL'];
		},

		dictionaries() {
			return this.$store.getters['REGISTER_STORE/DICTIONARIES'];
		},

		prepareApprovals() {
			let approvals = this.dictionaries.approvals ?? [];

			for (let i = 0; i < approvals.length; i++) {
				let name = approvals[i].name;
				let linkAndUrl = name.split('[[')[1];
				if (linkAndUrl) {
					let route = linkAndUrl.split(']]')[0];
					if (route) {
						let [name, routerName] = route.split('|');
						let href = '#';
						if (routerName) {
							let routeObject = this.$router.getRoutes().find(i => i.name === routerName);
							if (routeObject) {
								href = routeObject.path;
							}
						}
						let a = '<a class="blue--text" href="' + href + '" target="_blank">' + name + '</a>';
						approvals[i].name = approvals[i].name.replace('[[' + route + ']]', a)
					}
				}
			}

			return approvals
		},
	},
	data: () => ({
		...validationRules(),

		loadingBtnAction: false,

		checkbox: {},
		files: [],
	}),
	methods: {
		back() {

			this.$gtag.event(
				'Custom',
				{
					'klik': 'register-step-3-back',
				}
			);


			this.$store.commit('REGISTER_STORE/BACK_SET', true)
			this.$store.commit('REGISTER_STORE/STEP_SET', this.activeStep - 1)
		},

		register() {
			if (!this.$refs.form.validate()) {
				return;
			}

			let formData = new FormData();
			for (let i = 0; i < this.files.length; i++) {
				formData.append('file-' + i, this.files[i]);
			}

			formData.append('countFiles', this.files.length);
			formData.append('email', this.email);
			formData.append('approvals', JSON.stringify(this.checkbox));


			this.$gtag.event(
				'Custom',
				{
					'klik': 'register-step-register',
				}
			);

			this.loadingBtnAction = true;
			this.$store.dispatch('REGISTER_STORE/storeStepThree', formData)
				.then((resp) => {
					if (resp.data.success) {
						this.$router.push({name: 'Login'})
					}
					this.loadingBtnAction = false;
				})
				.catch(err => {
					console.error(err)
					this.loadingBtnAction = false;
				});

		},
		getSize(item) {
			let size = 0;
			for (let i = 0; i < item.length; i++) {
				size += item[i].size
			}

			return size < 10 * 1000 * 1000
		},
		deleteFile(indexFile) {
			this.files = this.files.filter((i, index) => index !== indexFile);
		}
	},
	watch: {},
	created() {
	},
};
</script>
