<template>
	<v-carousel
		hide-delimiter-background
		:height="height"
		:show-arrows="false"
		:interval="8000"
		class="authorization__carousel"
		cycle
	>
		<v-carousel-item
			v-for="(i, index) in testimonials"
			:key="index"
			:src="`/img/` + i.background"
		>
			<a
				v-if="i.href"
				:href="i.href"
				style="width: 100%; height: 100%"
				target="_blank"
			>

			</a>

			<v-sheet
				style="background: rgba(0,0,0,.8); border-radius: 10px"
				max-width="500px"
				class="text-center white--text pa-6 ma-auto mt-2 mb-2"
				v-else-if="i.testimonial_logo || i.testimonial_name || i.testimonial_desc || i.testimonial_text"
			>
				<v-icon size="30" color="white" class="mb-3">
					fas fa-quote-right
				</v-icon>

				<div class="text-justify text-body-2" v-html="i.testimonial_text"/>

				<div class="d-flex mt-6">
					<v-img
						v-if="i.testimonial_logo"
						max-width="120"
						:src="`/img/${i.testimonial_logo}`"
					/>
					<div class="text-body-1 pa-2 text-left">
						<div class="mb-2" v-html="i.testimonial_name"/>
						<div v-html="i.testimonial_desc"/>
					</div>
				</div>
			</v-sheet>

		</v-carousel-item>
	</v-carousel>
</template>
<script>
export default {
	name: 'BlockCarousel',
	components: {},
	computed: {
		testimonials() {
			return this.$store.getters['AUTH_STORE/TESTIMONIALS'];
		},
	},
	props: {
		height: {
			type: String,
			required: false,
			default: '100vh'
		},
	},
	data: () => ({}),
	methods: {},
	created() {
		this.$store.dispatch('AUTH_STORE/banners');
	},
};
</script>
