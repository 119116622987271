<template>
	<v-row>
		<div
			class="text-center grey--text text--darken-2 text-h5 font-weight-300 w-100">
			Step {{ activeStep }} of {{ countStep }}
		</div>
		<v-col cols="12">
			<p>To begin the registration process, fill in the fields below.</p>

			<p>Before you start, please prepare scans of your company register documents to
				enclose in the application form.</p>

			<p>Note: if your company is located in the European Union, please make sure that
				the
				document has an active VAT EU number at it.</p>
		</v-col>
		<v-col cols="12" xs="12" class="d-flex align-center">
			<v-spacer/>
			<div class="grey--text text--darken-2 font-weight-500 mr-2">
				Step {{ activeStep }} of {{ countStep }}
			</div>
			<v-btn
				large
				color="primary"
				@click="next()"
				class="ma-1"
			>
				NEXT STEP
			</v-btn>
		</v-col>

	</v-row>
</template>
<script>

export default {
	name: 'BlockRegisterInfo',
	components: {},
	computed: {
		activeStep() {
			return this.$store.getters['REGISTER_STORE/STEP'];
		},
		countStep() {
			return this.$store.getters['REGISTER_STORE/STEP_COUNT'];
		},
	},
	data: () => ({}),
	methods: {
		next() {
			this.$gtag.event(
				'Custom',
				{
					'klik': 'register-step-2',
				}
			);
			this.$store.commit('REGISTER_STORE/STEP_SET', this.activeStep + 1)
		},
	},
	watch: {},
	created() {
	},
};
</script>
