<template>
	<v-container fluid class="authorization authorization--login pa-0">
		<v-row no-gutters>
			<v-col cols="12" md="6">
				<div class="authorization__form form--login d-flex align-center">

					<div class="form__content my-auto">
						<div class="form__content--logo">
							<v-row>
								<v-col cols="12">
									<router-link
										:to="{name: 'Login'}"
										id="register-back"
									>
										<v-img
											class=""
											max-width="243"
											src="/img/logo_dark.png"
										/>
									</router-link>
								</v-col>
							</v-row>
						</div>
						<div class="form__content--form mt-4 pa-6">
							<v-row>
								<v-col cols="12">
									<h1 class="content__title">
										Registration Form to WOOKAH Wholesale Order Form
									</h1>
								</v-col>
							</v-row>

							<transition
								name="fade"
								mode="out-in"
								:duration="500"
								v-if="!loading"
							>

								<block-register-info
									v-if="activeStep === 1"
									:key="1"
								/>


								<block-register-user-info
									v-if="activeStep === 2"
									:key="2"
								/>

								<block-register-files-and-terms
									v-if="activeStep === 3"
									:key="3"
								/>


							</transition>

						</div>
					</div>
				</div>
			</v-col>
			<v-col cols="12" md="6" class="hidden-sm-and-down">
				<block-carousel/>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import BlockCarousel from "@/views/auth/Block/BlockCarousel";
import BlockRegisterInfo from "@/views/auth/Block/BlockRegisterInfo";
import BlockRegisterUserInfo from "@/views/auth/Block/BlockRegisterUserInfo";
import BlockRegisterFilesAndTerms from "@/views/auth/Block/BlockRegisterFilesAndTerms";

export default {
	name: 'Register',
	components: {BlockRegisterFilesAndTerms, BlockRegisterUserInfo, BlockRegisterInfo, BlockCarousel},
	computed: {
		activeStep() {
			return this.$store.getters['REGISTER_STORE/STEP'];
		},
	},
	data: () => ({
		loading: true,
	}),
	methods: {
		register() {
			this.$router.push({name: 'Login'});
		},
		toStep(step) {
			this.activeStep = step;
		},
	},
	watch: {
		files: function () {
			console.log(this.files);
		},
	},
	created() {
		this.$store.dispatch('REGISTER_STORE/index')
			.then(() => {
				this.loading = false
			})
			.catch(err => {
				console.error(err)
				this.loading = false
			});
	},
	destroyed() {
		this.$store.commit('REGISTER_STORE/STEP_SET', 1)
	}
};
</script>
