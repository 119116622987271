<template>
	<v-form
		ref="form"
		lazy-validation
	>
		<v-row>
			<div
				class="text-center grey--text text--darken-2 text-h5 font-weight-300 w-100">
				Step {{ activeStep }} of {{ countStep }}
			</div>
			<v-col cols="12">
				<h2 class="font-weight-300">Account information</h2>
			</v-col>
			<v-col cols="12">
				<v-text-field
					outlined
					label="Email*"
					hide-details="auto"
					dense
					:rules="requiredEmail"
					v-model="user.email"
				/>
			</v-col>

			<v-col cols="12">
				<v-text-field
					outlined
					label="Company name*"
					hide-details="auto"
					dense
					:rules="required"
					v-model="user.name"
				/>
			</v-col>

			<v-col cols="12">
				<v-text-field
					label="Street and building number*"
					outlined
					required
					hide-details="auto"
					dense
					:rules="required"
					v-model="user.street"
				/>
			</v-col>
			<v-col cols="12" sm="5">
				<v-text-field
					label="Postal code"
					outlined
					required
					hide-details="auto"
					dense
					v-model="user.postal_code"
				/>
			</v-col>
			<v-col cols="12" sm="7">
				<v-text-field
					label="City*"
					outlined
					required
					hide-details="auto"
					dense
					:rules="required"
					v-model="user.city"
				/>
			</v-col>

			<v-col cols="12">
				<v-select
					label="Country*"
					outlined
					required
					:items="dictionaries.countries"
					hide-details="auto"
					dense
					:rules="required"
					item-text="name"
					return-object
					v-model="user.country"
				/>
			</v-col>

			<v-col cols="12" v-if="user.country && user.country.eu">
				<v-text-field
					outlined
					label="VAT EU*"
					hide-details="auto"
					dense
					:rules="required"
					v-model="user.vatin"
				/>
			</v-col>

			<v-col cols="12" v-if="user.country && !user.country.eu">
				<v-text-field
					outlined
					label="Company data"
					hide-details="auto"
					dense
					v-model="user.company_data"
				/>
			</v-col>

			<v-col cols="12">
				<v-select
					label="Business type*"
					outlined
					required
					:items="dictionaries.client_types"
					hide-details="auto"
					dense
					item-text="name_en"
					:rules="required"
					v-model="user.types"
					return-object
					multiple
				/>
			</v-col>

			<v-col cols="12">
				<h2 class="font-weight-300 mb-2">Delivery addresses</h2>
				<v-btn small outlined color="green" @click="copyData">Copy registration data</v-btn>
			</v-col>

			<v-col cols="12">
				<v-text-field
					outlined
					label="Name*"
					hide-details="auto"
					dense
					:rules="required"
					v-model="address.name"
				/>
			</v-col>

			<v-col cols="12">
				<v-text-field
					label="Street and building number*"
					outlined
					required
					hide-details="auto"
					dense
					:rules="required"
					v-model="address.street"
				/>
			</v-col>
			<v-col cols="12" sm="5">
				<v-text-field
					label="Postal code"
					outlined
					required
					hide-details="auto"
					dense
					v-model="address.postal_code"
				/>
			</v-col>
			<v-col cols="12" sm="7">
				<v-text-field
					label="City*"
					outlined
					required
					hide-details="auto"
					dense
					:rules="required"
					v-model="address.city"
				/>
			</v-col>
			<v-col cols="12">
				<v-select
					label="Country*"
					outlined
					required
					:items="dictionaries.countries"
					hide-details="auto"
					dense
					item-text="name"
					:rules="required"
					return-object
					v-model="address.country"
				/>
			</v-col>

			<v-col cols="12">
				<h2 class="font-weight-300">Contact details</h2>
			</v-col>

			<v-col cols="12">
				<v-text-field
					label="Contact person*"
					outlined
					required
					hide-details="auto"
					dense
					:rules="required"
					v-model="contact.name"
				/>
			</v-col>

			<v-col cols="12">
				<v-text-field
					label="Email"
					outlined
					required
					hide-details="auto"
					dense
					:rules="notRequiredEmail"
					v-model="contact.email"
				/>
			</v-col>

			<v-col cols="12">
				<v-text-field
					label="Phone*"
					outlined
					required
					hide-details="auto"
					dense
					:rules="required"
					v-model="contact.phone"
				/>
			</v-col>

			<v-col cols="12" xs="12" class="d-flex align-center">
				<v-spacer/>
				<div class="grey--text text--darken-2 font-weight-500 mr-2">
					Step {{ activeStep }} of {{ countStep }}
				</div>
				<v-btn
					outlined
					large
					color="secondary"
					@click="back()"
					class="ma-1"
				>
					BACK
				</v-btn>
				<v-btn
					large
					color="primary"
					@click="next()"
					class="ma-1"
					:loading="loading"
				>
					NEXT STEP
				</v-btn>
			</v-col>
		</v-row>
	</v-form>
</template>
<script>
import {validationRules} from '@/helpers/validationRules'

export default {
	name: 'BlockRegisterUserInfo',
	components: {},
	computed: {
		backStep() {
			return this.$store.getters['REGISTER_STORE/BACK'];
		},

		activeStep() {
			return this.$store.getters['REGISTER_STORE/STEP'];
		},
		countStep() {
			return this.$store.getters['REGISTER_STORE/STEP_COUNT'];
		},

		dictionaries() {
			return this.$store.getters['REGISTER_STORE/DICTIONARIES'];
		},
	},
	data: () => ({
		...validationRules(),

		loading: false,

		user: {},
		address: {},
		contact: {},
	}),
	methods: {
		back() {
			this.$gtag.event(
				'Custom',
				{
					'klik': 'register-step-2-back',
				}
			);

			this.$store.commit('REGISTER_STORE/STEP_SET', this.activeStep - 1)
		},
		copyData() {
			this.address.name = this.user.name;
			this.address.street = this.user.street;
			this.address.postal_code = this.user.postal_code;
			this.address.city = this.user.city;
			this.address.country = this.user.country;
		},
		next() {
			if (!this.$refs.form.validate()) {
				return;
			}
			this.$gtag.event(
				'Custom',
				{
					'klik': 'register-step-3',
				}
			);

			this.$store.dispatch('REGISTER_STORE/storeStepTwo', {
				back: this.backStep,
				user: this.user,
				address: this.address,
				contact: this.contact,
			})
				.then((resp) => {
					if (resp.data.success) {
						this.$store.commit('REGISTER_STORE/STEP_SET', this.activeStep + 1);

						this.$store.commit('REGISTER_STORE/DATA_SET', {
							name: 'user',
							value: this._.cloneDeep(this.user),
						})
						this.$store.commit('REGISTER_STORE/DATA_SET', {
							name: 'address',
							value: this._.cloneDeep(this.address),
						})
						this.$store.commit('REGISTER_STORE/DATA_SET', {
							name: 'contact',
							value: this._.cloneDeep(this.contact),
						})
					}
					this.loading = false
				})
				.catch(err => {
					console.error(err)
					this.loading = false
				});

		},
	},
	watch: {},
	created() {
		this.user = this._.cloneDeep(this.$store.getters['REGISTER_STORE/USER'])
		this.address = this._.cloneDeep(this.$store.getters['REGISTER_STORE/ADDRESS'])
		this.contact = this._.cloneDeep(this.$store.getters['REGISTER_STORE/CONTACT'])
	},
};
</script>
